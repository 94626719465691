import React from 'react';
import { Button, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faUser, faHistory, faSignOut } from '@fortawesome/free-solid-svg-icons'

const Dashboard = (props) => {
    const navigate = useNavigate();
    const { cookies } = props;

    return (
        <div>
            <div>
                <Breadcrumb>
                    <BreadcrumbItem active>Hem</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div className="d-flex flex-column align-items-center">
                <h1>Mina sidor - {cookies.get('gc_name')}</h1>
                <br></br>
                <Row style={{ width: "80%" }}>
                    <Col xs="12" md="4" className="mb-3 mb-md-0">
                        <Button color="primary" block onClick={() => navigate('/current')} style={{ height: "20rem" }}>
                            <FontAwesomeIcon icon={faBank} />
                            <p>Hantera aktuellt lån</p>
                        </Button>
                    </Col>
                    <Col xs="12" md="4" className="mb-3 mb-md-0">
                        <Button color="primary" block onClick={() => navigate('/history')} style={{ height: "20rem" }}>
                            <FontAwesomeIcon icon={faHistory} />
                            <p>Se tidigare lån</p>
                        </Button>
                    </Col>
                    <Col xs="12" md="4">
                        <Button color="primary" block onClick={() => navigate('/contact')} style={{ height: "20rem" }}>
                            <FontAwesomeIcon icon={faUser} />
                            <p>Ändra kontaktuppgifter</p>
                        </Button>
                    </Col>
                    <hr className="mt-3"></hr>
                    <Col xs="12" md="12">
                        <Button color="primary" className="pt-3" block onClick={() => navigate('/logout')}>
                            <FontAwesomeIcon icon={faSignOut} />
                            <p>Logga ut</p>
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default withCookies(Dashboard);
